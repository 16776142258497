.category {
  @include grid(6, 3 * 2 * $gap, 6 * $gap);

  margin: 0 auto;

  // NOTE: needed due to auto-armgin
  width: 100%;
  max-width: $max-width;

  padding:
    3 * 2 * $gap
    2 * $gap
    0
    2 * $gap;

  @include breakpoint($breakpoint-1) {
    @include grid(6, 14 * $gap, 6 * $gap);

    padding:
      6.5 * $gap
      6.5 * $gap
      0
      6.5 * $gap;
  }

  &__heading {
    margin: 0;

    grid-column: 1 / span 6;

    font-size: $font-size-7;
    font-weight: $font-weight-4;
    letter-spacing: $letter-spacing-5;
    text-align: center;
    text-transform: uppercase;

    @include breakpoint($breakpoint-1) {
      font-size: $font-size-7-1;
    }
  }

  &__strong {
    font-weight: $font-weight-5;
  }

  &__list {
    margin: 0;
    padding: 0;
    list-style: none;

    grid-column: 1 / span 6;
  }

  &__item {
    display: flex;
    flex-wrap: wrap;

    margin: 0 -3 * $gap;

    &:nth-child(2n) {
      flex-direction: row-reverse;
    }

    & + & {
      margin-top: 2 * 2 * $gap;

      @include breakpoint($breakpoint-1) {
        margin-top: 12 * $gap;
      }
    }
  }

  &__image {
    margin: 0;

    width: 100%;

    padding: 0 3 * $gap;

    @include breakpoint($breakpoint-1) {
      width: 50%;
    }
  }

  &__image-holder {
    @include lazy-image-holder;
  }

  &__image-element {
    @include lazy-image;
  }

  &__information {
    width: 100%;

    padding:
      4 * $gap
      3 * $gap
      0
      3 * $gap;

    @include breakpoint($breakpoint-1) {
      width: 50%;
    }
  }

  &__model {
    margin: 0;

    font-size: $font-size-4;
    font-weight: $font-weight-5;
    letter-spacing: $letter-spacing-5;
    text-transform: uppercase;
  }

  &__table {
    @include table-reset;

    margin-top: 4 * $gap;
  }

  &__table-row {
    @include table-row-reset;

    & + & {
      border-top-color: transparent;
      border-top-style: solid;
      border-top-width: 0.5 * $gap;
    }
  }

  &__table-header {
    @include table-header-reset;
    @include better-word-breaks;

    width: math.div(100, 8) * 3 * 1%;

    padding-right: 3 * $gap;

    font-weight: $font-weight-4;

    @include breakpoint($breakpoint-1) {
      width: auto;

      padding-right: 6 * $gap;

      hyphens: none;
    }

    .category__table-row--is-price &,
    .category__table-row--is-special-offer-price & {
      letter-spacing: $letter-spacing-5;
      text-transform: uppercase;
    }

    .category__table-row--is-special-offer-price & {
      color: $color-3;
    }

    .category__table-row--is-price &,
    :not(.category__table-row--is-price) + .category__table-row--is-special-offer-price & {
      // TODO: this could be done with row border
      padding-top: 4 * $gap;
    }
  }

  &__table-data {
    @include table-data-reset;

    vertical-align: bottom;

    .category__table-row--is-price &,
    .category__table-row--is-special-offer-price & {
      letter-spacing: $letter-spacing-5;
      text-transform: uppercase;
    }

    .category__table-row--is-special-offer-price & {
      color: $color-3;
    }

    .category__table-row--is-price &,
    :not(.category__table-row--is-price) + .category__table-row--is-special-offer-price & {
      // TODO: this could be done with row border
      padding-top: 4 * $gap;
    }
  }

  &__frame-checkbox {
    display: none;
  }

  &__frame-label {
    margin-right: 0.25 * $gap;
    // TODO: this could be done with row border
    margin-bottom: 1.5 * $gap;

    position: relative;
    z-index: 0;

    display: inline-block;

    width: 4 * $gap * math.div(283, 162);
    height: 4 * $gap;

    cursor: zoom-in;

    transition: transform 150ms ease, z-index 0ms linear 150ms;

    .category__frame-checkbox:checked + & {
      z-index: 5;

      transform: scale(4);
      
      cursor: zoom-out;

      transition: transform 150ms ease;
    }
  }

  &__frame-svg-holder {
    display: inline-block;

    // scale SVG down to actual wanted size
    transform-origin: top left;
    transform: scale(math.div(1, 4));

    &::before {
      position: absolute;
      top: -2 * $gap;
      right: -2 * $gap;
      bottom: -2 * $gap;
      left: -2 * $gap;
      z-index: -1;
      
      display: none;

      border-color: $color-1;
      border-style: solid;
      border-width: 1px;
      
      background-color: $color-2;
      content: '';

      .category__frame-checkbox:checked + .category__frame-label & {
        display: block;
      }
    }
  }

  &__frame-svg {
    vertical-align: top;

    // make sure the SVG is large enough to prevent pixelation in Safari
    width: 4 * 4 * $gap * math.div(283, 162);
    height: 4 * 4 * $gap;

    // fix blur in Safari
    transform: translateZ(0);
  }
}
