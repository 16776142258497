.back-to-top {
  display: none;

  @include breakpoint($breakpoint-1) {
    @include fade-out;

    position: fixed;
    right: 3.25 * $gap;
    bottom: 3.25 * $gap;
    z-index: 5;

    display: block;

    text-align: right;
  }

  .headroom--not-top & {
    @include breakpoint($breakpoint-1) {
      @include fade-in;
    }
  }

  &__link {
    @include anchor-reset;

    font-size: $font-size-3;
    text-transform: uppercase;
  }
}
