.text-block {
  @include better-word-breaks;

  grid-column: 1 / span 6;

  @include breakpoint($breakpoint-1) {
    grid-column: 2 / span 4;
  }

  .heading-block + & {
    margin-top: -2 * $gap;

    @include breakpoint($breakpoint-1) {
      margin-top: -2 * 3 * $gap;
    }
  }

  a {
    @include anchor-reset;
    @include anchor-highlight;
  }

  p {
    margin: 0;
  }

  p + p {
    margin-top: 4 * $gap;
  }
}
