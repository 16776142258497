.bikes {
  @include grid(6, 3 * 2 * $gap, 6 * $gap);

  margin: 0 auto;

  // NOTE: needed due to auto-margin
  width: 100%;
  max-width: $max-width;

  padding:
    3 * 2 * $gap
    2 * $gap
    0
    2 * $gap;

  @include breakpoint($breakpoint-1) {
    @include grid(6, 14 * $gap, 6 * $gap);

    padding:
      6.5 * $gap
      6.5 * $gap
      0
      6.5 * $gap;
  }

  &__buttons {
    display: flex;
    align-items: center;
    flex-direction: column;

    grid-column: 1 / span 6;

    @include breakpoint($breakpoint-1) {
      flex-direction: row;
      justify-content: center;
    }
  }

  &__button {
    @include button-reset;

    cursor: pointer;
    font-size: $font-size-7;
    letter-spacing: $letter-spacing-5;
    text-transform: uppercase;

    @include breakpoint($breakpoint-1) {
      font-size: $font-size-7-1;
    }

    & + .bikes__button {
      margin-top: 1 * $gap;

      @include breakpoint($breakpoint-1) {
        margin-top: 0;
        margin-left: 6 * $gap;
      }
    }

    &[aria-current] {
      color: $color-3;
    }
  }

  &__list {
    margin: 0;
    padding: 0;
    list-style: none;

    @include fade-in-animation;

    display: none;

    flex-wrap: wrap;
    justify-content: center;

    margin:
      -2 * 2 * $gap
      -1 * $gap
      0
      -1 * $gap;

    grid-column: 1 / span 6;

    @include breakpoint($breakpoint-1) {
      margin:
        -6 * $gap
        -3 * $gap
        0
        -3 * $gap;
    }

    &--is-visible {
      display: flex;
    }
  }

  &__item {
    padding:
      2 * $gap
      1 * $gap
      0
      1 * $gap;

    @include breakpoint($breakpoint-1) {
      width: math.div(100, 3) * 1%;

      padding:
        6 * $gap
        3 * $gap
        0
        3 * $gap;
    }
  }

  &__link {
    @include anchor-reset;

    display: inline-block;

    border-style: solid;
    border-width: 2px;

    width: 100%;

    padding:
      2 * $gap
      3 * $gap;

    font-size: $font-size-7;
    letter-spacing: $letter-spacing-5;
    text-align: center;
    text-transform: uppercase;

    @include breakpoint($breakpoint-1) {
      padding: 3 * $gap;

      font-size: $font-size-7-1;
    }
  }
}
