.header {
  margin: 0 auto;

// NOTE: needed due to auto-margin
  width: 100%;
  max-width: $max-width;

  padding:
    2.5 * $gap
    2 * $gap
    0
    2 * $gap;

  @include breakpoint($breakpoint-1) {
    position: relative;

    display: flex;
    justify-content: flex-end;

    // add menu width to max-width to make space for menu offset
    max-width: $max-width + math.div(8.7rem, 1);

    padding:
      6.5 * $gap
      6.5 * $gap
      0
      6.5 * $gap;
  }

  &__logo {
    @include breakpoint($breakpoint-1) {
      width: 50%;
      max-width: math.div($max-width, 2) - 6.5 * $gap;
    }
  }

  &__logo-link {
    @include anchor-reset;
  }

  &__logo-img {
    vertical-align: top;

    width: 8rem;

    @include breakpoint($breakpoint-1) {
      width: 12.75rem;
    }
  }

  &__menu {
    display: flex;
    align-items: flex-end;
    flex-direction: column;

    position: fixed;
    top: 2.5 * $gap + 1 * $gap;
    right: 2 * $gap + 1 * $gap;
    bottom: 0;
    left: 2 * $gap + 1 * $gap;
    z-index: 5;

    pointer-events: none;

    @include breakpoint($breakpoint-1) {
      position: static;

      width: 50%;
    }

    .header__menu-checkbox:checked ~ & {
    }
  }

  &__menu-checkbox {
    display: none;
  }

  &__menu-brandmark-link {
    @include anchor-reset;
    @include fade-out;

    position: absolute;
    top: -1 * $gap;
    left: -1 * $gap;
    z-index: 5;

    width: 3.1rem;
    height: 1.95rem;
    overflow: hidden;

    pointer-events: auto;

    .header__menu-checkbox:checked ~ & {
      @include fade-in;
    }
  }

  &__menu-brandmark-img {
    vertical-align: top;

    width: 8rem;

    @include breakpoint($breakpoint-1) {
      display: none;
    }
  }

  &__menu-checkbox-label {
    // prevent list background transition covering checkbox
    position: relative;
    z-index: 5;

    display: flex;
    align-items: center;
    justify-content: center;

    width: 1.45 * math.div(25.01px, $base-size) * 1rem;
    height: 1.45 * math.div(16px, $base-size) * 1rem;

    cursor: pointer;
    
    pointer-events: auto;
    user-select: none;

    transition:
      border-color 0.1s ease,
      color 0.1s ease;

    @include breakpoint($breakpoint-1) {
      display: none;
    }

    &:hover {
      @media (hover: hover) {
        border-color: $color-3;
        color: $color-3;
      }
    }

    .header__menu-checkbox:checked ~ & {
      border-color: $color-3;
      color: $color-3;
    }
  }

  &__menu-checkbox-label-svg {
    @include fade-in-animation;

    width: 100%;
    height: 100%;

    .header__menu-checkbox:checked ~ .header__menu-checkbox-label &--bars {
      display: none;
    }

    .header__menu-checkbox:not(:checked) ~ .header__menu-checkbox-label &--x {
      display: none;
    }
  }

  &__menu-list {
    margin: 0;
    padding: 0;
    list-style: none;

    @include fade-out;

    margin-top: 2.5 * 3 * $gap;

    pointer-events: auto;

    @include breakpoint($breakpoint-1) {
      @include fade-in;

      margin-top: 0;
    }

    &::before {
      position: absolute;
      // compensate .header__menu position
      top: -2.5 * $gap - 1 * $gap;
      right: -2 * $gap - 1 * $gap;
      bottom: 0;
      left: -2 * $gap - 1 * $gap;
      z-index: -1;

      background-color: $color-2;
      content: '';

      @include breakpoint($breakpoint-1) {
        display: none;
      }
    }

    .header__menu-checkbox:checked ~ & {
      @include fade-in;
    }
  }

  &__menu-item {
    // text-align: center;

    @include breakpoint($breakpoint-1) {
      // text-align: left;
    }

    & + & {
      margin-top: 0.75 * $gap;
    }
  }

  &__menu-link {
    @include anchor-reset;
    @include anchor-highlight;

    font-size: $font-size-5;
    letter-spacing: $letter-spacing-5;
    text-transform: uppercase;
  }
}
