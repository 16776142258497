@font-face {
  font-family: 'Miso';
  font-style: normal;
  font-weight: $font-weight-4;
  src: url('/assets/fonts/miso-regular-webfont.eot');
  src:
    url('../fonts/miso-regular-webfont.eot?#iefix') format('embedded-opentype'),
    url('../fonts/miso-regular-webfont.woff2') format('woff2'),
    url('../fonts/miso-regular-webfont.woff') format('woff'),
    url('../fonts/miso-regular-webfont.ttf') format('truetype'),
    url('../fonts/miso-regular-webfont.svg#misoregular') format('svg');
}

@font-face {
  font-family: 'Miso';
  font-style: normal;
  font-weight: $font-weight-5;
  src: url('/assets/fonts/miso-bold-webfont.eot');
  src:
    url('../fonts/miso-bold-webfont.eot?#iefix') format('embedded-opentype'),
    url('../fonts/miso-bold-webfont.woff2') format('woff2'),
    url('../fonts/miso-bold-webfont.woff') format('woff'),
    url('../fonts/miso-bold-webfont.ttf') format('truetype'),
    url('../fonts/miso-bold-webfont.svg#misobold') format('svg');
}
