.default {
  @include grid(6, 3 * 2 * $gap, 6 * $gap);

  margin: 0 auto;

  // NOTE: needed due to auto-margin
  width: 100%;
  max-width: $max-width;

  padding:
    3 * 2 * $gap
    2 * $gap
    0
    2 * $gap;

  @include breakpoint($breakpoint-1) {
    @include grid(6, 14 * $gap, 6 * $gap);

    padding:
      6.5 * $gap
      6.5 * $gap
      0
      6.5 * $gap;
  }
}
