.logo-collection-block {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  margin:
    -3 * $gap
    -3 * $gap
    0
    -3 * $gap;

  grid-column: 1 / span 6;

  @include breakpoint($breakpoint-1) {
    margin:
      -6 * $gap
      -3 * $gap
      0
      -3 * $gap;
  }

  &__image {
    margin: 0;

    width: math.div(100, 2) * 1%;

    padding:
      3 * $gap
      3 * $gap
      0
      3 * $gap;

    @include breakpoint($breakpoint-1) {
      width: math.div(100, 6) * 1%;

      padding:
        6 * $gap
        3 * $gap
        0
        3 * $gap;
    }
  }

  &__image-holder {
    @include lazy-image-holder;
  }

  &__image-element {
    @include lazy-image;
  }
}

