.line-block {
  margin: 0;
  border: none;

  grid-column: 1 / span 6;

  // NOTE: this is needed to work
  width: 100%;

  border-top-color: $color-1;
  border-top-style: solid;
  border-top-width: 3px;
}
