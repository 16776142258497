*,
*::before,
*::after {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;

  // make it fill the height so the footer an be pushed down
  height: 100%;

  background-color: $color-2;
  color: $color-1;
  font-family: $font-family-1;
  font-size: $base-size;
  font-weight: $font-weight-4;
  letter-spacing: $letter-spacing-4;
  line-height: $line-height-4;

  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

body {
  // push footer down
  display: grid;
  grid-template-rows: auto 1fr auto;
  min-height: 100%;
}

// prevent elements from overflowing horizontically
// NOTE: the extra lines are needed to work in iOS Safari
html {
  overflow-x: hidden;
}
body {
  position: relative;
  overflow-x: hidden;
}
