.heading-block {
  margin: 0;

  grid-column: 1 / span 6;

  font-size: $font-size-7;
  font-weight: $font-weight-5;
  letter-spacing: $letter-spacing-5;
  text-align: center;
  text-transform: uppercase;

  @include breakpoint($breakpoint-1) {
    font-size: $font-size-7-1;
  }

  .line-block + & {
    margin:
        -1 * $gap
        0
        -1 * $gap
        0;

    @include breakpoint($breakpoint-1) {
      margin:
        -4 * $gap
        0
        -3 * $gap
        0;
    }
  }
}
