.home {
  @include grid(6, 3 * 2 * $gap, 6 * $gap);

  margin: 0 auto;
  max-width: $max-width;

  padding:
    3 * 2 * $gap
    2 * $gap
    0
    2 * $gap;

  @include breakpoint($breakpoint-1) {
    @include grid(6, 14 * $gap, 6 * $gap);

    padding:
      6.5 * $gap + 4 * $gap
      6.5 * $gap
      0
      6.5 * $gap;
  }
  
  &__cover-text {
    align-self: flex-end;

    grid-column: 1 / span 6;

    font-size: $font-size-6;
    letter-spacing: $letter-spacing-5;
    text-transform: uppercase;

    @include breakpoint($breakpoint-1) {
      grid-column: 1 / span 2;

      font-size: $font-size-6-1;
    }
  }

  &__cover-text br {
    content: '';

    &::after {
      content: ' ';
    }
  }

  &__cover-image {
    margin: 0;

    grid-column: 1 / span 6;
    grid-row: 1;

    @include breakpoint($breakpoint-1) {
      margin-top: 0;

      grid-column: 3 / span 4;
    }
  }

  &__cover-image-holder {
    @include lazy-image-holder;
  }

  &__cover-image-element {
    @include lazy-image;
  }

  &__video {
    position: relative;

    grid-column: 1 / span 6;
  }

  &__video-element {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;
  }

  &__intro-text {
    grid-column: 1 / span 6;

    @include breakpoint($breakpoint-1) {
      grid-column: 1 / span 3;
    }
  }

  &__intro-text a {
    @include anchor-reset;
    @include anchor-highlight;
  }

  &__intro-text p {
    margin: 0;

    & + p {
      margin-top: 4 * $gap;
    }
  }

  &__intro-image {
    margin: 0;

    grid-column: 1 / span 6;

    @include breakpoint($breakpoint-1) {
      grid-column: 4 / span 3;
    }
  }

  &__intro-image-holder {
    @include lazy-image-holder;
  }

  &__intro-image-element {
    @include lazy-image;
  }

  &__current-issue-image {
    margin: 0;

    grid-column: 1 / span 6;

    @include breakpoint($breakpoint-1) {
      grid-column: 1 / span 3;
    }
  }

  &__current-issue-image-holder {
    @include lazy-image-holder;
  }

  &__current-issue-image-element {
    @include lazy-image;
  }

  &__current-issue-text {
    grid-column: 1 / span 6;

    @include breakpoint($breakpoint-1) {
      grid-column: 4 / span 3;
    }
  }

  &__current-issue-text p {
    margin: 0;

    & + p {
      margin-top: 4 * $gap;
    }
  }

  &__contact-text {
    grid-column: 1 / span 6;

    @include breakpoint($breakpoint-1) {
      grid-column: 1 / span 2;
    }
  }

  &__contact-text a {
    @include anchor-reset;
    @include anchor-highlight;
  }

  &__contact-text p {
    margin: 0;

    & + p {
      margin-top: 4 * $gap;
    }
  }

  &__contact-image {
    margin: 0;

    order: 1;

    grid-column: 1 / span 6;

    @include breakpoint($breakpoint-1) {
      order: 0;

      grid-column: 3 / span 4;
    }
  }

  &__contact-image-holder {
  }

  &__contact-image-element {
    width: 100%;
  }

  &__opening-hours {
    border-collapse: collapse;
    // table-layout: fixed;

    // make it look like a regular new paragraph
    margin-top: -2 * $gap;
    
    grid-column: 1 / span 6;

    // fix table width in Safari
    width: 100%;

    @include breakpoint($breakpoint-1) {
      margin-top: 0;

      grid-column: 1 / span 2;
    }

    // &--on-contact-page {
    //   @include breakpoint($breakpoint-1) {
    //     margin-top: -2 * 3 * $gap;

    //     // grid-column: 3 / span 2;
    //     grid-column: 2 / span 3;
    //   }

    //   @include breakpoint($breakpoint-2) {
    //     grid-column: 2 / span 2;
    //   }
    // }
  }

  &__opening-hours-row {
    vertical-align: top;
  }

  &__opening-hours-header {
    font-weight: $font-weight-4;
    text-align: left;

    .home__opening-hours-row--is-offset:not(:nth-child(1)) & {
      padding-top: 4 * $gap;
    }
  }

  &__opening-hours-data {
    letter-spacing: $letter-spacing-5;
    text-align: right;
    text-transform: uppercase;

    .home__opening-hours-row--is-offset & {
      padding-top: 4 * $gap;
    }
  }
}
