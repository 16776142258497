@mixin anchor-highlight {
  transition: color 150ms ease;

  &:hover,
  &[aria-current] {
    color: $color-3;
  }
}

@mixin anchor-reset {
  color: inherit;
  text-decoration: none;
}

@mixin anchor-target-offset($offset: 0, $margin: 0) {
  // compensate anchor target offset
  margin-top: -$offset + $margin;

  // add anchor target offset
  padding-top: $offset;
}

@mixin better-word-breaks {
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
}

@mixin breakpoint($width) {
  @media (min-width: #{$width}) {
    @content;
  }
}

@mixin button-reset {
  // outline: none;
  border: none;
  padding: 0;

  background: none;
  color: inherit;
  font: inherit;
  text-align: left;
  -webkit-appearance: none;
}

@mixin clickarea-extension($x: 0, $y: 0) {
  &::after {
    box-sizing: content-box;

    position: absolute;
    top: -$y;
    left: -$x;

    width: 100%;
    height: 100%;

    padding: $y $x;

    content: '';
  }
}

@mixin crisp-image {
  image-rendering:-moz-crisp-edges;
  image-rendering:-o-crisp-edges;
  image-rendering:-webkit-optimize-contrast;
  image-rendering:optimize-contrast;
  -ms-interpolation-mode:nearest-neighbor;
}

@mixin fade-in {
  // NOTE: order is important in Safari
  visibility: visible;
  opacity: 1;

  transition: opacity 200ms ease-out;
}

@mixin fade-out {
  visibility: hidden;
  opacity: 0;

  transition: visibility 0ms 200ms, opacity 200ms ease-out;
}

// keyframes for fade-in-animation mixin
@keyframes fade-in-animation {
  from {
    opacity: 0;
  }
  
  to {
    opacity: 1;
  }
}

@mixin fade-in-animation {
  animation: fade-in-animation 250ms;
  animation-fill-mode: forwards;
}

@mixin grid(
  $grid-columns: 12,
  $grid-row-gap: 0,
  $grid-column-gap: 0,
) {
  display: grid;
  align-content: start;
  grid-column-gap: $grid-column-gap;
  grid-row-gap: $grid-row-gap;
  grid-template-columns: repeat($grid-columns, minmax(0, 1fr));
}

@mixin hr-reset {
  box-sizing: inherit;
  margin: 0;
  border: none;
  overflow: auto;
}

@mixin lazy-image-holder {
  position: relative;

  // prevent (preview) images from sticking out
  // overflow: hidden;

  background-color: $color-3;
}

@mixin lazy-image {
  @include fade-in;

  position: absolute;
  top: 0;
  left: 0;

  width: 100%;

  // NOTE: this is needed for lozad to work (preventing 0px height)
  height: 100%;

  // prevent placeholder color shining through transparent images
  background-color: $color-2;

  &.lazy {
    @include fade-out;
  }
}

@mixin right-arrow {
  &::before {
    display: inline-block;
    
    transform: rotate(90deg);
    
    // use whitespace width (from font metrics) as margin
    margin-right: 0.2em;

    // magic number
    margin-left: 0.1em;
    
    content: '\02191';
  }
}

@mixin table-reset {
  // table-layout: fixed;

  border-collapse: collapse;

  // fix table width in Safari
  width: 100%;
}

@mixin table-data-reset {
  padding: 0;
}

@mixin table-header-reset {
  padding: 0;

  text-align: left;
}

@mixin table-row-reset {
  vertical-align: top;
}
