// naming guidelines:
// * breakpoint modifiers are appended to variable names (e. g. $width and
//   $width-medium)
// * normal modifieres are prepended (e. g. $width and $medium-width)

// colors

$color-1: hsl(0, 0%, 0%);
$color-2: hsl(0, 0%, 100%);
$color-3: hsl(356, 89%, 47%);
$color-4: hsl(0, 0%, 75%);

// typography

$font-family-1: 'Miso', sans-serif;

$base-size: 27px;

$font-size-3: 0.675rem;
$font-size-4: 1rem;
$font-size-5: 1.35rem;
$font-size-6: 0.65 * 1.85rem;
$font-size-7: 0.8 * 2.2rem;

$font-size-6-1: 1.85rem;
$font-size-7-1: 2.2rem;

$letter-spacing-3: 0;
$letter-spacing-4: 0.01em;
$letter-spacing-5: 0.15em;

$line-height-3: 0.8;
$line-height-4: 1;
$line-height-5: 1.2;

$font-weight-3: 300;
$font-weight-4: 400;
$font-weight-5: 500;

// layout

$gap: 0.25 * $line-height-4 * 1rem;

// width restriction
$max-width: 42rem + 2 * 4 * $gap;

// breakpoints
// NOTE: it’s ok to use px values because browsers handle zooming correctly 
// whereas em and rem values are handled inconsistently

// default is e. g. iphone

// e. g. ipad portrait
$breakpoint-1: 768px;

// e. g. ipad horizontal
$breakpoint-2: 1024px;

// e. g. macbook 13"
$breakpoint-3: 1280px;

// e. g.macbook 15"
$breakpoint-4: 1440px;

// e. g. macbook 16"
$breakpoint-5: 1792px;
