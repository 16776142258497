.footer {
  display: flex;
  justify-content: space-between;

  margin: 0 auto;

  // NOTE: needed due to auto-margin
  width: 100%;
  max-width: $max-width;

  padding: 
    2.5 * 4 * $gap
    2 * $gap
    2 * $gap
    2 * $gap;

  @include breakpoint($breakpoint-1) {
    align-items: flex-end;

    padding:
      20 * $gap
      6.5 * $gap
      1.5 * 6.5 * $gap
      6.5 * $gap;
  }

  &__claim {
    color: $color-3;
    font-weight: $font-weight-5;
    font-size: $font-size-7;
    letter-spacing: $letter-spacing-5;

    @include breakpoint($breakpoint-1) {
      font-size: $font-size-7-1;
    }
  }

  &__menu {
    margin-top: 10 * $gap;

    @include breakpoint($breakpoint-1) {
      margin-top: 0;
    }
  }

  &__menu-list {
    margin: 0;
    padding: 0;
    list-style: none;

    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;

    @include breakpoint($breakpoint-2) {
      flex-direction: row;
    }
  }

  &__menu-item {
    & + & {
      @include breakpoint($breakpoint-1) {
        margin-left: 4 * $gap;
      }
    }

    .header & {
      @include breakpoint($breakpoint-1) {
        display: none;
      }
    }

    .header__menu-item + & {
      margin-top: 2 * $gap;
    }
  }

  &__menu-link {
    @include anchor-reset;
    @include anchor-highlight;

    line-height: $line-height-5;
    letter-spacing: $letter-spacing-5;
    text-transform: uppercase;
  }
}
